import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About.js";
import Contact from "./pages/Contact.js";
import Services from "./pages/Services.js";
import Transcript from "./pages/Transcript";
import Signup from "./pages/Signup";
import AccountCheck from "./Components/Account_check";
// import ReunionTicket from "./Components/Reunion_ticket";
// import ReunionVendorTicket from "./Components/Reunion_vendor_ticket";
// import ElectionCandidateTicket from "./Components/Election_candidate_ticket";
import VerifyPayment from "./pages/Verify_payment";
import VerifyClearance from "./pages/Verify_clearance.js";
// import VotePage from "./pages/Vote/VotePage";

function App() {
  return (
    <Router className="App">
      <Switch>
        <Route path="/" exact component={Home}></Route>
        <Route path="/about" component={About}></Route>
        <Route path="/services" component={Services}></Route>
        <Route path="/contact" component={Contact}></Route>
        <Route path="/transcript" component={Transcript}></Route>
        <Route path="/signup" component={Signup}></Route>
        <Route path="/payment-verify" component={VerifyPayment}></Route>
        <Route path="/clearance-verify" component={VerifyClearance}></Route>
        {/* <Route path="/vote" component={VotePage}></Route> */}
      </Switch>

      <AccountCheck />
      {/* <ReunionTicket /> */}
      {/* <ReunionVendorTicket /> */}
      {/* <ElectionCandidateTicket /> */}
    </Router>
  );
}

export default App;
