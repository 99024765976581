import Footer from "../Components/Footer";
import TopBar from "../Components/TopBar";
import { FaAngleDoubleRight, FaUserCheck } from "react-icons/fa";
// import { FaAngleDoubleRight, FaUserCheck } from "react-icons/fa";
import { MdError } from "react-icons/md";
import { useDispatch } from "react-redux";
// import { openModal } from "../redux/modalSlice";
// import Countdown from 'react-countdown';
// import { Link } from "react-router-dom";
// import styled from "styled-components";

function Home() {
  // const dispatch = useDispatch();
  return (
    <div>
      <TopBar />
      <div className="p-8 hidden bg-red-500  text-white m">
        <h2 className="text-4xl mb-2">IMPORTANT NOTICE</h2>
        <p className="text-lg text-white">
          Please be informed that the final year clearance is temporarily halted
          as we perform some important modifications as directed by the founder.
          During this time, you will not be able to complete your final year
          clearance.
        </p>
        <p className="text-lg text-white">
          We apologise for any inconvenience this may cause and appreciate your
          patience. We will be back up an running in no time.
        </p>
      </div>
      <div className="flex py-8 bg-school-boy bg-no-repeat bg-cover bg-left md:bg-bottom">
        {/* message */}
        <div className="mt-8 self-end mx-4 space-y-6 md:ml-auto md:mx-8 md:my-28 md:max-w-xl md:px-8 md:py-8 md:bg-white md:bg-opacity-80 md:rounded-md">
          <h1 className="text-white mb-40  text-3xl font-semibold text-center md:mb-0 md:hidden">
            Welcome
          </h1>
          {/* Clearance buttons */}
          <div className="space-y-4">
          <a
              href="https://alumni.abuad.edu.ng/dev/register.html?balance=xx/xxxxx/xxx"
              className="hover:bg-primary hover:text-white text-base text-gray-700 rounded px-4 py-6 flex justify-between items-center bg-white duration-200 md:bg-primary-dark md:text-white

              "
            >
              <p className="">I already paid 15,500</p>
              <span>
                <FaAngleDoubleRight />
              </span>
            </a>
            <a
              href="https://alumni.abuad.edu.ng/dev/register.html"
              className="hover:bg-primary hover:text-white text-base text-gray-700 rounded px-4 py-6 flex justify-between items-center bg-white duration-200 md:bg-primary-dark md:text-white

              "
            >
              <p className="">Final year clearance</p>
              <span>
                <FaAngleDoubleRight />
              </span>
            </a>

            <a
              target="_blank"
              rel="noreferrer"
              href={`https://wa.me/2349030009002?text=HI%2C%20I%20am%20having%20issues%20making%20my%20payment.%20my%20email%20address%20is%3A`}
              className="rounded px-4 py-3 flex items-center bg-yellow-500 hover:bg-yellow-600 text-gray-700 duration-200"
            >
              <span className="text-white text-lg mr-4">
                <MdError />
              </span>
              <div>
                <p className="text-white text-base">
                  Report clearance or payment issue
                </p>
                <p className="text-white text-xs">
                  (Please do not expect instant response. Maximum response time
                  is 12hours)
                </p>
              </div>
            </a>
          </div>

          {/* Check account */}
          {/* <div className="space-y-4">
            <div>
              <h2 className="font-bold text-base text-white md:text-gray-700">
                We might have created an account for you
              </h2>
              <p className="text-sm text-white md:text-gray-700">
                If you completed your final year clearance online, you might
                already have an account
              </p>
            </div>

            <span
              onClick={() => dispatch(openModal("accountCheck"))}
              className="cursor-pointer hover:bg-primary hover:text-white rounded px-4 py-3 flex items-center justify-center bg-white text-gray-700 duration-200 md:bg-primary-dark md:text-white"
            >
              <span className="text-lg mr-4">
                <FaUserCheck />
              </span>
              <p className="text-sm"> Check if you have an account</p>
            </span>
          </div> */}

          {/* Check account */}
          <div className="space-y-4">
            <div>
              <h2 className="font-bold text-base text-white md:text-gray-700">
                Do you have issues completing your clearance?
              </h2>
              <p className="text-sm text-white md:text-gray-700">
                If you are facing issues completing your final year clearance,
                kindly click the orange button above to contact support.
              </p>
            </div>

            {/* <span
              onClick={() => dispatch(openModal("accountCheck"))}
              className="cursor-pointer hover:bg-primary hover:text-white rounded px-4 py-3 flex items-center justify-center bg-white text-gray-700 duration-200 md:bg-primary-dark md:text-white"
            >
              <span className="text-lg mr-4">
                <FaUserCheck />
              </span>
              <p className="text-sm"> Check if you have an account</p>
            </span> */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
